@import url(~bootstrap/dist/css/bootstrap.css);
@import url(~react-datetime/css/react-datetime.css);
@import url(~material-design-iconic-font/dist/css/material-design-iconic-font.css);
@import url(~react-bootstrap-toggle/dist/bootstrap2-toggle.css);
@import url(~react-toggle/style.css);
@import url(~leaflet/dist/leaflet.css);
@import url(~react-toastify/dist/ReactToastify.css);
/*------- custom css -------*/
@import url("https://fonts.googleapis.com/css?family=Comfortaa:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
html {
  height: 100%; }

body {
  position: relative; }

html, body {
  color: #444;
  background-color: #fff;
  font-weight: 400;
  letter-spacing: 0.03rem; }

.container.boxed {
  width: 100% !important;
  max-width: 1300px; }

.container {
  max-width: 1260px; }

.content {
  background: #f7f7f7;
  padding: 30px 65px; }

img {
  -webkit-backface-visibility: hidden; }

/*--- spacers ---*/
.spacer {
  margin: 15px 0;
  line-height: 0; }

.spacer-2x {
  margin: 30px 0;
  line-height: 0; }

.spacer-3x {
  margin: 45px 0;
  line-height: 0; }

.spacer-line {
  width: 50px;
  line-height: 0;
  border-bottom: 2px solid #eee;
  margin-left: auto;
  margin-right: auto; }

.spacer-line-2x {
  width: 100px;
  line-height: 0;
  border-bottom: 2px solid #eee;
  margin-left: auto;
  margin-right: auto; }

.spacer-line-solid {
  width: 50px;
  line-height: 0;
  border-bottom: 4px solid #eee;
  margin-left: auto;
  margin-right: auto; }

.spacer-line-solid-2x {
  width: 100px;
  line-height: 0;
  border-bottom: 4px solid #eee;
  margin-left: auto;
  margin-right: auto; }

.spacer-line-full-width {
  width: 100%;
  line-height: 0;
  border-bottom: 2px solid #eee; }

.space {
  margin: 15px !important; }

.space-2x {
  margin: 30px !important; }

.space-3x {
  margin: 45px !important; }

.space-top {
  margin-top: 15px !important; }

.space-top-2x {
  margin-top: 30px !important; }

.space-bottom {
  margin-bottom: 15px !important; }

.space-bottom-2x {
  margin-bottom: 30px !important; }

.space-left {
  margin-left: 15px !important; }

.space-left-2x {
  margin-left: 30px !important; }

.space-right {
  margin-right: 15px !important; }

.space-right-2x {
  margin-right: 30px !important; }

.inner-space {
  padding: 15px !important; }

.inner-space-2x {
  padding: 30px !important; }

.inner-space-3x {
  padding: 45px !important; }

.inner-space-top {
  padding-top: 15px !important; }

.inner-space-top-2x {
  padding-top: 30px !important; }

.inner-space-bottom {
  padding-bottom: 15px !important; }

.inner-space-bottom-2x {
  padding-bottom: 30px !important; }

.inner-space-left {
  padding-left: 15px !important; }

.inner-space-left-2x {
  padding-left: 30px !important; }

.inner-space-right {
  padding-right: 15px !important; }

.inner-space-right-2x {
  padding-right: 30px !important; }

.no-margin {
  margin: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-margin-left {
  margin-left: 0 !important; }

.no-margin-right {
  margin-right: 0 !important; }

.no-padding {
  padding: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

/*--- end of spacers ---*/
/*--- custom colors ---*/
.bg-transparent {
  background-color: transparent !important; }

.bg-white {
  background-color: #fff !important; }

.bg-black {
  background-color: #000 !important; }

.bg-faded {
  background-color: #e9f0f4 !important; }

.bg-light {
  background-color: #f7f7f7 !important; }

.bg-lightgrey {
  background-color: #eee !important; }

.bg-grey {
  background-color: #aaa !important; }

.bg-midgrey {
  background-color: #777 !important; }

.bg-darkgrey {
  background-color: #333 !important; }

.bg-deepgrey {
  background-color: #222 !important; }

.bg-inverse {
  background-color: #111 !important; }

.bg-primary {
  background-color: #9c64b8 !important; }

.bg-primary-hover {
  background-color: #8853a1 !important; }

.bg-secondary {
  background-color: #e9f0f4 !important; }

.bg-secondary-hover {
  background-color: #cdd4d8 !important; }

.bg-success {
  background-color: #17d381 !important; }

.bg-success-hover {
  background-color: #0db26a !important; }

.bg-info {
  background-color: #21b9d5 !important; }

.bg-info-hover {
  background-color: #1397af !important; }

.bg-warning {
  background-color: #ffa03b !important; }

.bg-danger {
  background-color: #f03739 !important; }

.w-border {
  border: 1px solid transparent; }

.w-border-2x {
  border: 2px solid transparent; }

.w-border-3x {
  border: 3px solid transparent; }

.border-white {
  border-color: #fff !important; }

.border-black {
  border-color: #000 !important; }

.border-faded {
  border-color: #e9f0f4 !important; }

.border-inverse {
  border-color: #333 !important; }

.border-primary {
  border-color: #9c64b8 !important; }

.border-secondary {
  border-color: #e9f0f4 !important; }

.border-success {
  border-color: #17d381 !important; }

.border-info {
  border-color: #21b9d5 !important; }

.border-warning {
  border-color: #ffa03b !important; }

.border-danger {
  border-color: #f03739 !important; }

.text-muted {
  color: #aaa !important; }

.text-white {
  color: #fff !important; }

.text-black {
  color: #000 !important; }

.text-primary {
  color: #9c64b8 !important; }

.text-secondary {
  color: #e9f0f4 !important; }

.text-success {
  color: #17d381 !important; }

.text-info {
  color: #21b9d5 !important; }

.text-warning {
  color: #ffa03b !important; }

.text-danger {
  color: #f03739 !important; }

a.text-white:focus, a.text-white:hover {
  color: #f7f7f7 !important; }

a.text-black:focus, a.text-black:hover {
  color: #555 !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #8853a1 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #cdd4d8 !important; }

a.text-success:focus, a.text-success:hover {
  color: #0db26a !important; }

a.text-info:focus, a.text-info:hover {
  color: #1397af !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #df7f19 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #c41a1c !important; }

/*--- end of custom colors ---*/
/*--- custom attributes ---*/
.container.full-width {
  width: 100% !important;
  padding: 0 !important; }

.w80 {
  max-width: 80% !important; }

.w70 {
  max-width: 70% !important; }

.w60 {
  max-width: 60% !important; }

.w50 {
  max-width: 50% !important; }

.w40 {
  max-width: 40% !important; }

.w35 {
  max-width: 35% !important; }

.w30 {
  max-width: 30% !important; }

.w20 {
  max-width: 20% !important; }

.w10 {
  max-width: 10% !important; }

button:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.opc-75 {
  opacity: 0.75 !important; }

.opc-50 {
  opacity: 0.5 !important; }

.opc-25 {
  opacity: 0.25 !important; }

.pull-left {
  float: left !important; }

.pull-right {
  float: right !important; }

.no-border {
  border: none !important; }

.no-border-radius {
  border-radius: 0 !important; }

.rectangle {
  border-radius: 0 !important; }

.rounded {
  border-radius: 5px !important; }

.rounded-2x {
  border-radius: 10px !important; }

.pill {
  border-radius: 50px !important; }

.circle {
  border-radius: 100% !important; }

h3.sub-title {
  font-size: 1.5rem; }

.sub-title {
  font-size: 1.25rem; }

.title-font {
  font-family: "Roboto Slab", serif !important; }

img {
  width: 100%;
  max-width: 100%;
  height: auto; }

.img-auto {
  width: auto !important; }

.img-thumbnail {
  padding: 5px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-bottom: 15px; }

.fa {
  vertical-align: middle; }

.animated {
  -webkit-animation-duration: 1s !important;
  animation-duration: 1s !important; }

.animated.fast {
  -webkit-animation-duration: 0.4s !important;
  animation-duration: 0.4s !important; }

.animated.first {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 0.5s; }

.animated.second {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1s; }

.animated.third {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 1.3s; }

.animated.fourth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1.8s; }

.animated.fifth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2s; }

.animated.sixth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2.5s; }

.animated.seventh {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.2s; }

.animated.eighth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 3.5s; }

.animated.nineth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.8s; }

::-moz-selection {
  color: #fff;
  background: rgba(156, 100, 184, 0.9); }

::selection {
  color: #fff;
  background: rgba(156, 100, 184, 0.9); }

.vcenter {
  display: flex;
  align-items: center; }

.va-middle {
  vertical-align: middle !important; }

.parallax {
  background-attachment: fixed !important; }

.hidden {
  display: none !important; }

.text-regular {
  font-weight: 400; }

.d-inline-block {
  display: inline-block; }

.after-dropdown {
  margin-left: 10px; }

.move {
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.move:hover {
  transform: translateY(-2px) !important; }

.no-move:hover {
  transform: translateY(0) !important; }

.raised {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 1px 6px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 1px 6px rgba(0, 0, 0, 0.1) !important; }

.raised.move:hover {
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05), 0 3px 12px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05), 0 3px 12px rgba(0, 0, 0, 0.1) !important; }

.no-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important; }

.count {
  position: absolute;
  top: 3px;
  right: 3px;
  color: #000;
  background: #fff;
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%; }

.count-inverse {
  color: #fff;
  background: #333; }

.count-primary {
  color: #fff;
  background: #9c64b8; }

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

/*--- end of custom attributes ---*/
/*--- preloader ---*/
.dark #preloader {
  background-color: #111; }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999999; }

.preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px; }

.preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #9c64b8;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite; }

.preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s; }

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  100% {
    transform: scale(1, 1);
    opacity: 0; } }

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0; } }

/*--- end of preloader ---*/
/*------- end of custom css -------*/
/*------- typography -------*/
html, body {
  font-size: 14px;
  background-color: #f7f7f7; }

html.has-sticky-top, body.has-sticky-top {
  height: auto; }

html, body, h1, h2, h3, h4, h5, h6, a, p, ul, ol, li {
  font-family: "Comfortaa", cursive !important;
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-family: "Roboto Slab", serif !important;
  font-weight: 700;
  letter-spacing: 0.1rem;
  margin-top: 0; }

.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
  color: #fff; }

a {
  color: #9c64b8;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  letter-spacing: 0.03rem;
  font-weight: 400;
  outline: 0 !important; }
  a:hover, a:active, a:focus {
    color: #8853a1;
    text-decoration: none; }

.dark a {
  color: #fff; }

.dark a:hover {
  color: #eee; }

p {
  font-family: "Comfortaa", cursive !important;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #444;
  letter-spacing: 0.03rem;
  margin-bottom: 10px; }

.dark p {
  color: #eee; }

ul {
  list-style: none;
  padding: 0; }

ul, ol {
  padding-left: 16px;
  margin-top: 0;
  margin-bottom: 20px; }

.list-featured {
  list-style: none;
  padding: 0; }

.list-featured li {
  position: relative;
  padding-left: 14px;
  margin-bottom: 7px; }

.list-featured li:before {
  position: absolute;
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 0;
  top: .5rem;
  background-color: #9c64b8; }

ol li {
  margin-bottom: 7px; }

li {
  color: #444;
  font-size: 1rem;
  letter-spacing: 0.03rem; }

.dark li {
  color: #eee; }

.blockquote {
  border-left: 0.25rem solid #9c64b8;
  padding: 0.5rem 1rem; }

.blockquote-reverse {
  border-right: 0.25rem solid #9c64b8;
  border-left: 0; }

blockquote p {
  font-size: 1rem;
  font-weight: 400; }

.blockquote-footer {
  color: #444;
  background: transparent;
  border: 0;
  padding-bottom: 0.75rem; }

cite {
  color: #aaa;
  font-size: 0.8rem; }

.mark, mark {
  background-color: #cdd4d8; }

b, strong {
  font-weight: 700; }

.text-uppercase {
  text-transform: uppercase; }

.lead {
  font-size: 1.1rem; }

.text-sm {
  font-size: 0.9rem; }

.text-xs {
  font-size: 0.75rem; }

form label {
  color: #000;
  display: inline-block;
  margin-bottom: .5rem; }

.md-icon {
  font-family: 'Material Icons';
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 1;
  font-style: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-item {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.icon-item-inner {
  background: #e9f0f4;
  padding: 15px 5px;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.icon-item-inner:hover {
  background: #9c64b8; }

.icon-item-inner i {
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.icon-item-inner:hover i {
  color: #fff; }

.icon-item-inner .caption-sm {
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.icon-item-inner:hover .caption-sm {
  color: #fff; }

.md-icon.dp10 {
  font-size: 10px;
  width: 10px;
  height: 10px;
  vertical-align: middle; }

.md-icon.dp12 {
  font-size: 12px;
  width: 12px;
  height: 12px;
  vertical-align: middle; }

.md-icon.dp14 {
  font-size: 14px;
  width: 14px;
  height: 14px;
  vertical-align: middle; }

.md-icon.dp16 {
  font-size: 16px;
  width: 16px;
  height: 16px;
  vertical-align: middle; }

.md-icon.dp18 {
  font-size: 18px;
  width: 18px;
  height: 18px;
  vertical-align: middle; }

.md-icon.dp20 {
  font-size: 20px;
  width: 20px;
  height: 20px;
  vertical-align: middle; }

.md-icon.dp24 {
  font-size: 24px;
  width: 24px;
  height: 24px;
  vertical-align: middle; }

.md-icon.dp30 {
  font-size: 30px;
  width: 30px;
  height: 30px;
  vertical-align: middle; }

.md-icon.dp36 {
  font-size: 36px;
  width: 36px;
  height: 36px;
  vertical-align: middle; }

.md-icon.dp48 {
  font-size: 48px;
  width: 48px;
  height: 48px;
  vertical-align: middle; }

.caption {
  font-size: .9rem; }

.caption-sm {
  font-size: .625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: .5rem; }

/*------- end of typography -------*/
/*---------- components ----------*/
/*------- alerts-badges -------*/
.alert {
  padding: 15px 20px;
  margin-bottom: 25px;
  border: 1px solid transparent;
  border-radius: 2px; }

.alert-dismissible .close {
  top: -.75rem;
  right: -1.25rem;
  padding: .75rem 1.25rem;
  position: relative; }

.close {
  float: right;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  opacity: .8; }

.alert-icon {
  line-height: 1;
  vertical-align: middle; }

.alert-success {
  background-color: #17d381;
  border-color: #0db26a;
  color: #fff; }

.alert-success .close {
  color: #0db26a; }

.alert-info {
  background-color: #21b9d5;
  border-color: #1397af;
  color: #fff; }

.alert-info .close {
  color: #1397af; }

.alert-warning {
  background-color: #ffa03b;
  border-color: #df7f19;
  color: #fff; }

.alert-warning .close {
  color: #df7f19; }

.alert-danger {
  background-color: #f03739;
  border-color: #c41a1c;
  color: #fff; }

.alert-danger .close {
  color: #c41a1c; }

.badge {
  color: #fff;
  font-size: 65%;
  font-weight: 700;
  border-radius: 2px;
  padding: .5em .8em; }

.badge-pill {
  border-radius: 10rem; }

.badge-default {
  background-color: #444; }

.badge-primary {
  background-color: #9c64b8; }

.badge-success {
  background-color: #17d381; }

.badge-info {
  background-color: #21b9d5; }

.badge-warning {
  background-color: #ffa03b; }

.badge-danger {
  background-color: #f03739; }

/*------- end of alerts-badges -------*/
/*------- buttons -------*/
.btn {
  font-family: "Comfortaa", cursive;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.06rem;
  border: 2px solid transparent;
  border-radius: 2px;
  padding: 16px 25px;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

a.btn, a.btn-social {
  display: inline-block; }

.btn span {
  vertical-align: middle; }

.btn-group-lg > .btn, .btn-lg {
  padding: 18px 28px;
  font-size: 1.25rem;
  border-radius: 3px; }

.btn-group-sm > .btn, .btn-sm {
  padding: 8px 14px;
  font-size: .8rem;
  border-radius: .2px; }

.btn-group-xs > .btn, .btn-xs {
  padding: 6px 10px;
  font-size: .6rem;
  border-radius: 2px; }

.btn-circle {
  display: inline-block;
  line-height: 1;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  max-width: 50px !important;
  max-height: 50px !important; }

.btn-circle i {
  font-size: 1rem;
  line-height: 1.95; }

.btn.btn-w-icon .md-icon.dp16 {
  line-height: 1.5rem; }

.btn-square {
  height: 40px;
  width: 40px;
  border-radius: 2px;
  padding: 3px !important; }

/*--- button colors ---*/
.btn-primary, .btn-primary:focus, .btn-primary.active {
  color: #fff !important;
  background-color: #9c64b8 !important;
  border-color: #9c64b8 !important; }

.btn-primary:hover, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #8853a1 !important;
  border-color: #8853a1 !important; }

.btn-outline-primary, .btn-outline-primary:focus, .btn-outline-primary.active, .btn-outline-primary:active {
  color: #9c64b8 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #9c64b8 !important; }

.btn-outline-primary:hover, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #9c64b8 !important;
  border-color: #9c64b8 !important; }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff !important;
  background-color: #9c64b8 !important;
  border-color: #9c64b8 !important;
  opacity: 0.8 !important; }

.btn-secondary, .btn-secondary:focus, .btn-secondary.active {
  color: #000 !important;
  background-color: #e9f0f4 !important;
  border-color: #e9f0f4 !important; }

.btn-secondary:hover, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  color: #000 !important;
  background-color: #cdd4d8 !important;
  border-color: #cdd4d8 !important; }

.btn-outline-secondary, .btn-outline-secondary:focus, .btn-outline-secondary.active, .btn-outline-secondary:active {
  color: #cdd4d8 !important;
  background-image: none;
  background-color: transparent;
  border-color: #cdd4d8 !important; }

.btn-outline-secondary:hover, .show > .btn-outline-secondary.dropdown-toggle {
  color: #000 !important;
  background-color: #cdd4d8 !important;
  border-color: #cdd4d8 !important; }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff !important;
  background-color: #e9f0f4 !important;
  border-color: #e9f0f4 !important;
  opacity: 0.8 !important; }

.btn-success, .btn-success:focus, .btn-success.active, .btn-success:active {
  color: #fff !important;
  background-color: #17d381 !important;
  border-color: #17d381 !important; }

.btn-success:hover, .show > .btn-success.dropdown-toggle {
  color: #fff !important;
  background-color: #0db26a !important;
  border-color: #0db26a !important; }

.btn-outline-success, .btn-outline-success:focus, .btn-outline-success.active, .btn-outline-success:active {
  color: #17d381 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #17d381 !important; }

.btn-outline-success:hover, .show > .btn-outline-success.dropdown-toggle {
  color: #fff !important;
  background-color: #17d381 !important;
  border-color: #17d381 !important; }

.btn-success.disabled, .btn-success:disabled {
  color: #fff !important;
  background-color: #17d381 !important;
  border-color: #17d381 !important;
  opacity: 0.8 !important; }

.btn-info, .btn-info:focus, .btn-info.active, .btn-info:active {
  color: #fff !important;
  background-color: #21b9d5 !important;
  border-color: #21b9d5 !important; }

.btn-info:hover, .show > .btn-info.dropdown-toggle {
  color: #fff !important;
  background-color: #1397af !important;
  border-color: #1397af !important; }

.btn-outline-info, .btn-outline-info:focus, .btn-outline-info.active, .btn-outline-info:active {
  color: #21b9d5 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #21b9d5 !important; }

.btn-outline-info:hover, .show > .btn-outline-info.dropdown-toggle {
  color: #fff !important;
  background-color: #21b9d5 !important;
  border-color: #21b9d5 !important; }

.btn-info.disabled, .btn-info:disabled {
  color: #fff !important;
  background-color: #21b9d5 !important;
  border-color: #21b9d5 !important;
  opacity: 0.8 !important; }

.btn-warning, .btn-warning:focus, .btn-warning.active, .btn-warning:active {
  color: #fff !important;
  background-color: #ffa03b !important;
  border-color: #ffa03b !important; }

.btn-warning:hover, .show > .btn-warning.dropdown-toggle {
  color: #fff !important;
  background-color: #df7f19 !important;
  border-color: #df7f19 !important; }

.btn-outline-warning, .btn-outline-warning:focus, .btn-outline-warning.active, .btn-outline-warning:active {
  color: #ffa03b !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #ffa03b !important; }

.btn-outline-warning:hover, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff !important;
  background-color: #ffa03b !important;
  border-color: #ffa03b !important; }

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff !important;
  background-color: #17d381 !important;
  border-color: #17d381 !important;
  opacity: 0.8 !important; }

.btn-danger, .btn-danger:focus, .btn-danger.active, .btn-danger:active {
  color: #fff !important;
  background-color: #f03739 !important;
  border-color: #f03739 !important; }

.btn-danger:hover, .show > .btn-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #c41a1c !important;
  border-color: #c41a1c !important; }

.btn-outline-danger, .btn-outline-danger:focus, .btn-outline-danger.active, .btn-outline-danger:active {
  color: #f03739 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #f03739 !important; }

.btn-outline-danger:hover, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #f03739 !important;
  border-color: #f03739 !important; }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff !important;
  background-color: #f03739 !important;
  border-color: #f03739 !important;
  opacity: 0.8 !important; }

.btn-white, .btn-white:focus, .btn-white.active, .btn-white:active {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important; }

.btn-white:hover, .show > .btn-white.dropdown-toggle {
  color: #000 !important;
  background-color: #eee !important;
  border-color: #eee !important; }

.btn-outline-white, .btn-outline-white:focus, .btn-outline-white.active, .btn-outline-white:active {
  color: #fff !important;
  background-image: none;
  background-color: transparent !important;
  border-color: white !important; }

.btn-outline-white:hover, .show > .btn-outline-white.dropdown-toggle {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important; }

.btn-white.disabled, .btn-white:disabled {
  opacity: 0.8 !important; }

.btn-black, .btn-black:focus, .btn-black.active, .btn-black:active {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important; }

.btn-black:hover, .show > .btn-black.dropdown-toggle {
  color: #fff !important;
  background-color: #333 !important;
  border-color: #333 !important; }

.btn-outline-black, .btn-outline-black:focus, .btn-outline-black.active, .btn-outline-black:active {
  color: #000 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #000 !important; }

.btn-outline-black:hover, .show > .btn-outline-black.dropdown-toggle {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important; }

.btn-black.disabled, .btn-black:disabled {
  opacity: 0.8; }

.btn-link, .btn-link:focus, .btn-link.active, .btn-link:active {
  color: #9c64b8; }

.btn-link:hover, .show > .btn-link.dropdown-toggle {
  color: #8853a1 !important;
  text-decoration: none; }

.btn-link.disabled, .btn-link:disabled {
  color: #9c64b8;
  opacity: 0.8 !important; }

.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

/*--- end of button colors ---*/
/*--- social buttons ---*/
.btn-social {
  font-family: "Comfortaa", cursive;
  font-weight: 400;
  font-size: .95rem;
  line-height: 1.25;
  letter-spacing: 0.03rem;
  border-radius: 0.35rem;
  padding: .75rem 1rem;
  vertical-align: middle;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.btn-social i {
  font-size: .9rem;
  vertical-align: middle; }

.btn-social span {
  margin-left: 10px;
  vertical-align: middle; }

.btn-social.first {
  padding-left: 0 !important;
  margin-left: 0 !important; }

.btn-social-lg {
  font-size: 1.3rem; }

.btn-social-lg i {
  font-size: 1.25rem; }

/* facebook button */
.btn-facebook, .btn-facebook:active, .btn-facebook:focus {
  color: #fff;
  background: #4e68a1;
  border: 2px solid #4e68a1; }

.btn-facebook:hover {
  color: #fff;
  background: #3b4f7a;
  border: 2px solid #3b4f7a; }

.btn-facebook-link, .btn-facebook-link:active, .btn-facebook-link:focus {
  color: #4e68a1;
  background: transparent;
  border: none; }

.btn-facebook-link:hover {
  color: #3b4f7a; }

.btn-outline-facebook, .btn-outline-facebook:active, .btn-outline-facebook:focus {
  color: #4e68a1;
  background: transparent;
  border: 2px solid #4e68a1; }

.btn-outline-facebook:hover {
  color: #fff !important;
  background: #4e68a1;
  border: 2px solid #4e68a1; }

/* twitter button */
.btn-twitter, .btn-twitter:active, .btn-twitter:focus {
  color: #fff;
  background: #65b5f2;
  border: 2px solid #65b5f2; }

.btn-twitter:hover {
  color: #fff;
  background: #5294c6;
  border: 2px solid #5294c6; }

.btn-twitter:hover {
  color: #fff;
  background: #5294c6;
  border: 2px solid #5294c6; }

.btn-twitter-link, .btn-twitter-link:active, .btn-twitter-link:focus {
  color: #65b5f2;
  background: transparent;
  border: none; }

.btn-twitter-link:hover {
  color: #5294c6; }

.btn-outline-twitter, .btn-outline-twitter:active, .btn-outline-twitter:focus {
  color: #65b5f2;
  background: transparent;
  border: 2px solid #65b5f2; }

.btn-outline-twitter:hover {
  color: #fff;
  background: #65b5f2;
  border: 2px solid #65b5f2; }

/* google button */
.btn-google, .btn-google:active, .btn-google:focus {
  color: #fff;
  background: #e05d4b;
  border: 2px solid #e05d4b; }

.btn-google:hover {
  color: #fff;
  background: #b94c3d;
  border: 2px solid #b94c3d; }

.btn-google-link, .btn-google-link:active, .btn-google-link:focus {
  color: #e05d4b;
  background: transparent;
  border: none; }

.btn-google-link:hover {
  color: #b94c3d; }

.btn-outline-google, .btn-outline-google:active, .btn-outline-google:focus {
  color: #e05d4b;
  background: transparent;
  border: 2px solid #e05d4b; }

.btn-outline-google:hover {
  color: #fff;
  background: #e05d4b;
  border: 2px solid #e05d4b; }

/* linkedin button */
.btn-linkedin, .btn-linkedin:active, .btn-linkedin:focus {
  color: #fff;
  background: #2083bc;
  border: 2px solid #2083bc; }

.btn-linkedin:hover {
  color: #fff;
  background: #186592;
  border: 2px solid #186592; }

.btn-linkedin-link, .btn-linkedin-link:active, .btn-linkedin-link:focus {
  color: #2083bc;
  background: transparent;
  border: none; }

.btn-linkedin-link:hover {
  color: #186592; }

.btn-outline-linkedin, .btn-outline-linkedin:active, .btn-outline-linkedin:focus {
  color: #2083bc;
  background: transparent;
  border: 2px solid #2083bc; }

.btn-outline-linkedin:hover {
  color: #fff;
  background: #2083bc;
  border: 2px solid #2083bc; }

/* pinterest button */
.btn-pinterest, .btn-pinterest:active, .btn-pinterest:focus {
  color: #fff;
  background: #d2373b;
  border: 2px solid #d2373b; }

.btn-pinterest:hover {
  color: #fff;
  background: #ad2c2f;
  border: 2px solid #ad2c2f; }

.btn-pinterest-link, .btn-pinterest-link:active, .btn-pinterest-link:focus {
  color: #d2373b;
  background: transparent;
  border: none; }

.btn-pinterest-link:hover {
  color: #ad2c2f; }

.btn-outline-pinterest, .btn-outline-pinterest:active, .btn-outline-pinterest:focus {
  color: #d2373b;
  background: transparent;
  border: 2px solid #d2373b; }

.btn-outline-pinterest:hover {
  color: #fff;
  background: #d2373b;
  border: 2px solid #d2373b; }

/* dribble button */
.btn-dribbble, .btn-dribbble:active, .btn-dribbble:focus {
  color: #fff;
  background: #ec5f94;
  border: 2px solid #ec5f94; }

.btn-dribbble:hover {
  color: #fff;
  background: #b4446e;
  border: 2px solid #b4446e; }

.btn-dribbble-link, .btn-dribbble-link:active, .btn-dribbble-link:focus {
  color: #ec5f94;
  background: transparent;
  border: none; }

.btn-dribbble-link:hover {
  color: #b4446e; }

.btn-outline-dribbble, .btn-outline-dribbble:active, .btn-outline-dribbble:focus {
  color: #ec5f94;
  background: transparent;
  border: 2px solid #ec5f94; }

.btn-outline-dribbble:hover {
  color: #fff;
  background: #ec5f94;
  border: 2px solid #ec5f94; }

/* instagram button */
.btn-instagram, .btn-instagram:active, .btn-instagram:focus {
  color: #fff;
  background: #4c5fd7;
  border: 2px solid #4c5fd7; }

.btn-instagram:hover {
  color: #fff;
  background: #4252ba;
  border: 2px solid #4252ba; }

.btn-instagram-link, .btn-instagram-link:active, .btn-instagram-link:focus {
  color: #4c5fd7;
  background: transparent;
  border: none; }

.btn-instagram-link:hover {
  color: #4252ba; }

.btn-outline-instagram, .btn-outline-instagram:active, .btn-outline-instagram:focus {
  color: #4c5fd7;
  background: transparent;
  border: 2px solid #4c5fd7; }

.btn-outline-instagram:hover {
  color: #fff !important;
  background: #4c5fd7;
  border: 2px solid #4c5fd7; }

/* youtube button */
.btn-youtube, .btn-youtube:active, .btn-youtube:focus {
  color: #fff;
  background: #e52d27;
  border: 2px solid #e52d27; }

.btn-youtube:hover {
  color: #fff;
  background: #b31217;
  border: 2px solid #b31217; }

.btn-youtube-link, .btn-youtube-link:active, .btn-youtube-link:focus {
  color: #e52d27;
  background: transparent;
  border: none; }

.btn-youtube-link:hover {
  color: #b31217; }

.btn-outline-youtube, .btn-outline-youtube:active, .btn-outline-youtube:focus {
  color: #e52d27;
  background: transparent;
  border: 2px solid #e52d27; }

.btn-outline-youtube:hover {
  color: #fff !important;
  background: #e52d27;
  border: 2px solid #e52d27; }

/*--- end of social buttons ---*/
/*------- end of buttons -------*/
/*------- cards -------*/
.card {
  background-color: #fff;
  border: 1px solid #e9f0f4;
  border-radius: 2px;
  margin-bottom: 1.5rem; }

.card-body .md-icon {
  display: inline; }

.card-rounded {
  border-radius: 2rem; }

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: #e9f0f4;
  border-bottom: 1px solid #cdd4d8; }

.card-header:first-child {
  border-radius: calc(.35rem - 1px) calc(.35rem - 1px) 0 0; }

.card-footer {
  padding: .75rem 1.25rem;
  background-color: #e9f0f4;
  border-top: 1px solid #cdd4d8; }

.card-inverse {
  background-color: #333;
  border-color: #333; }

.card-secondary {
  background-color: #e9f0f4 !important;
  border-color: #cdd4d8 !important; }

.card-primary {
  background-color: #9c64b8 !important;
  border-color: #8853a1 !important; }

.card-success {
  background-color: #17d381;
  border-color: #0db26a; }

.card-info {
  background-color: #21b9d5;
  border-color: #1397af; }

.card-warning {
  background-color: #ffa03b;
  border-color: #df7f19; }

.card-danger {
  background-color: #f03739;
  border-color: #c41a1c; }

.card-outline-inverse {
  background-color: #fff;
  border-color: #333; }

.card-outline-secondary {
  background-color: #fff;
  border-color: #cdd4d8 !important; }

.card-outline-primary {
  background-color: #fff;
  border-color: #8853a1; }

.card-outline-success {
  background-color: #fff;
  border-color: #0db26a; }

.card-outline-info {
  background-color: #fff;
  border-color: #1397af; }

.card-outline-warning {
  background-color: #fff;
  border-color: #df7f19; }

.card-outline-danger {
  background-color: #fff;
  border-color: #c41a1c; }

.card-inverse .card-blockquote, .card-inverse .card-footer, .card-inverse .card-header, .card-inverse .card-title {
  color: #fff; }

.card-inverse .card-blockquote .blockquote-footer, .card-inverse .card-link, .card-inverse .card-subtitle, .card-inverse .card-text {
  color: rgba(255, 255, 255, 0.65); }

/*------- end of cards -------*/
/*------- carousel -------*/
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: .9rem;
  margin: 5px;
  padding: 4px 7px;
  background: #e9f0f4;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #8853a1;
  color: #fff;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #e9f0f4;
  display: block;
  -webkit-backface-visibility: visible;
  border-radius: 30px;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #9c64b8; }

/*--- custom carousels ---*/
.owl-theme.carousel-full-nav .owl-nav {
  margin: 0; }

.carousel-full-nav .owl-prev {
  position: absolute;
  top: 47%;
  left: 0;
  transform: translate(25%, -50%);
  color: rgba(255, 255, 255, 0.75) !important;
  background: transparent !important;
  font-size: 2.5rem !important; }

.carousel-full-nav .owl-prev:hover {
  color: #fff; }

.carousel-full-nav .owl-next {
  position: absolute;
  top: 47%;
  right: 0;
  transform: translate(-25%, -50%);
  color: rgba(255, 255, 255, 0.75) !important;
  background: transparent !important;
  font-size: 2.5rem !important; }

.carousel-full-nav .owl-next:hover {
  color: #fff; }

/*--- end of custom carousels ---*/
/*------- end of carousel -------*/
/*------- forms -------*/
.form-control {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 13px 15px;
  font-family: "Comfortaa", cursive;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  color: #cdd4d8;
  background-color: #fff;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 2px solid #cdd4d8;
  border-radius: 2px;
  -webkit-appearance: none;
  appearance: none;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s; }

.input-group-btn .btn {
  height: 100%; }

.form-inline.dark .form-control {
  color: #fff;
  background: #777;
  border: 2px solid #777; }

.bg-primary .form-inline.dark .form-control {
  color: #000;
  background: #fff;
  border: 2px solid #fff; }

.form-control.pill {
  background-color: #fff !important;
  border: 2px solid #fff;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.form-control.pill:hover {
  border-color: #fff !important; }

.form-control.pill:focus {
  border-color: #fff !important; }

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  padding: .6075rem .7rem;
  font-size: .8rem; }

.form-control-xs, .input-group-xs > .form-control, .input-group-xs > .input-group-addon, .input-group-xs > .input-group-btn > .btn {
  padding: .45rem .5rem;
  font-size: .6rem; }

.qty {
  max-width: 45px;
  max-height: 40px;
  border-radius: 5px;
  margin-left: 0 !important;
  padding: 13px 5px 13px 8px; }

select.form-control.shop-option:not([size]):not([multiple]) {
  height: 40px; }

input, textarea {
  font-family: "Comfortaa", cursive !important;
  letter-spacing: 0.03rem; }

.input-group-addon:not(:last-child) {
  border: none; }

.input-group-addon {
  border: none; }

.input-group-btn:not(:last-child) > .btn, .input-group-btn:not(:last-child) > .btn-group {
  margin-right: -2px; }

.input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group {
  margin-left: -2px; }

input:focus, textarea:focus {
  color: #cdd4d8 !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #9c64b8 !important; }

.form-inline.dark input:focus, .form-inline.dark textarea:focus {
  color: #fff !important;
  border-color: #9c64b8; }

.bg-primary .form-inline.dark input:focus, .bg-primary .form-inline.dark textarea:focus {
  color: #000 !important;
  border-color: #9c64b8; }

input[type=datetime-local] {
  padding: 11px 15px; }

/*--- input placeholder color ---*/
::-webkit-input-placeholder {
  color: #cdd4d8 !important; }

::-moz-placeholder {
  color: #cdd4d8 !important; }

:-ms-input-placeholder {
  color: #cdd4d8 !important; }

:-moz-placeholder {
  color: #cdd4d8 !important; }

.form-inline.dark ::-webkit-input-placeholder {
  color: #fff !important; }

.form-inline.dark ::-moz-placeholder {
  color: #fff !important; }

.form-inline.dark :-ms-input-placeholder {
  color: #fff !important; }

.form-inline.dark :-moz-placeholder {
  color: #fff !important; }

.bg-primary .form-inline.dark ::-webkit-input-placeholder {
  color: #000 !important; }

.bg-primary .form-inline.dark ::-moz-placeholder {
  color: #000 !important; }

.bg-primary .form-inline.dark :-ms-input-placeholder {
  color: #000 !important; }

.bg-primary .form-inline.dark :-moz-placeholder {
  color: #000 !important; }

/*--- end of input placeholder color ---*/
select.form-control:not([size]):not([multiple]) {
  height: 47px; }

select.selector {
  -webkit-appearance: menulist !important; }

select.multiple-selector {
  -webkit-appearance: listbox !important;
  max-height: 55px; }

select:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #cdd4d8 !important; }

option {
  padding: 4px 2px 1px; }

textarea:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #cdd4d8 !important; }

.btn-file {
  position: relative;
  overflow: hidden;
  font-family: "Comfortaa", cursive;
  letter-spacing: 0.06rem !important; }

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 1rem;
  font-family: "Comfortaa", cursive;
  font-weight: 400;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block; }

.checkbox label {
  display: inline-block;
  position: relative;
  font-weight: 400;
  padding-left: 5px;
  margin-bottom: 0; }

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  margin-top: 1px;
  margin-left: -20px;
  border: 1px solid #cdd4d8;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  margin-top: 1px;
  margin-left: -20px;
  padding-left: 4px;
  padding-top: 2px;
  font-size: 10px;
  color: #cdd4d8; }

.checkbox input[type="checkbox"] {
  opacity: 0; }

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Material Icons';
  content: "\E876"; }

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65; }

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #e9f0f4;
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #9c64b8;
  border-color: #9c64b8; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-secondary input[type="checkbox"]:checked + label::before {
  background-color: #cdd4d8;
  border-color: #cdd4d8; }

.checkbox-secondary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #17d381;
  border-color: #17d381; }

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #21b9d5;
  border-color: #21b9d5; }

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffa03b;
  border-color: #ffa03b; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #f03739;
  border-color: #f03739; }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.radio label {
  display: inline-block;
  position: relative;
  font-weight: 400;
  padding-left: 5px; }

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cdd4d8;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 0;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out; }

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 12px;
  height: 12px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #cdd4d8;
  margin-top: 0;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }

.radio input[type="radio"] {
  opacity: 0; }

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }

.radio input[type="radio"]:disabled + label {
  opacity: 0.65; }

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }

.radio.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #9c64b8; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #9c64b8; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #9c64b8; }

.radio-success input[type="radio"] + label::after {
  background-color: #17d381; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #17d381; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #17d381; }

.radio-info input[type="radio"] + label::after {
  background-color: #21b9d5; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #21b9d5; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #21b9d5; }

.radio-warning input[type="radio"] + label::after {
  background-color: #ffa03b; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffa03b; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffa03b; }

.radio-danger input[type="radio"] + label::after {
  background-color: #f03739; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f03739; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #f03739; }

label.hidden {
  display: none !important; }

label.btn-file {
  margin-bottom: 0; }

.form-wrapper {
  background: #fff;
  border: 2px solid #e9f0f4;
  padding: 30px; }

.restaurant-demo .form-wrapper {
  background: rgba(17, 17, 17, 0.6);
  border: 2px solid rgba(17, 17, 17, 0.1); }

.form-title {
  font-size: 1.375rem;
  margin-bottom: 30px; }

.form-inline-extras {
  display: table;
  width: 100%;
  margin-top: 10px; }

.form-inline-extras .checkbox {
  margin-left: 2px; }

.custom-form .left-area {
  display: inline-block;
  width: 50%;
  text-align: left;
  vertical-align: middle; }

.custom-form .right-area {
  display: inline-block;
  width: 50%;
  text-align: right;
  vertical-align: middle; }

.custom-form .left-area.long {
  width: 75%; }

.custom-form .right-area.long {
  width: 75%; }

.custom-form .left-area.short {
  width: 25%; }

.custom-form .right-area.short {
  width: 25%; }

.form-extras {
  display: table;
  width: 100%;
  margin-top: 15px; }

.btn .submit-button {
  line-height: 1.75rem; }

/*------- end of forms -------*/
/*------- modals -------*/
.example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block; }

.modal-content {
  background-color: #fff;
  border: 1px solid #cdd4d8;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); }

.modal-header {
  border-bottom: 0; }

.modal-body p {
  margin-bottom: 0; }

.modal-body.multi-paragraph p {
  margin-bottom: 10px; }

.modal-footer {
  border-top: 0; }

[role=button], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

.inverse.modal-content .modal-title {
  color: #fff; }

.inverse.modal-content .modal-body * {
  color: #eee; }

.inverse button.close {
  color: #fff; }

.modal-inverse.modal-content {
  background-color: #000;
  border: 1px solid #333; }

.modal-inverse .modal-header, .modal-inverse .modal-footer {
  border-color: #333; }

.modal-primary.modal-content {
  background-color: #9c64b8;
  border: 1px solid #8853a1; }

.modal-primary .modal-header, .modal-primary .modal-footer {
  border-color: #8853a1; }

.modal-success.modal-content {
  background-color: #17d381;
  border: 1px solid #0db26a; }

.modal-success .modal-header, .modal-success .modal-footer {
  border-color: #0db26a; }

.modal-info.modal-content {
  background-color: #21b9d5;
  border: 1px solid #1397af; }

.modal-info .modal-header, .modal-info .modal-footer {
  border-color: #1397af; }

.modal-warning.modal-content {
  background-color: #ffa03b;
  border: 1px solid #df7f19; }

.modal-warning .modal-header, .modal-warning .modal-footer {
  border-color: #df7f19; }

.modal-danger.modal-content {
  background-color: #f03739;
  border: 1px solid #c41a1c; }

.modal-danger .modal-header, .modal-danger .modal-footer {
  border-color: #c41a1c; }

.modal.terms {
  z-index: 1060; }

/*------- end of modals -------*/
/*------- navigations -------*/
/*--- breadcrumb ---*/
.breadcrumb {
  padding: 1rem 1.4rem;
  background-color: transparent;
  border-radius: 2px; }

.breadcrumb.text-center .breadcrumb-item, .breadcrumb.text-right .breadcrumb-item {
  float: none; }

.breadcrumb-transparent {
  background-color: transparent;
  padding: 1rem 0; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  padding-left: .5rem;
  color: #cdd4d8;
  content: "/"; }

.breadcrumb-item.active {
  color: #8853a1; }

.dark .breadcrumb-item + .breadcrumb-item::before {
  color: #eee; }

.dark .breadcrumb-item.active {
  color: #eee; }

.dark .breadcrumb-item.active:hover {
  color: #aaa; }

/*--- end of breadcrumb ---*/
/*--- navs ---*/
.nav-link.disabled {
  color: #cdd4d8;
  cursor: not-allowed; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #9c64b8;
  background-color: #fff;
  border-color: #cdd4d8 #cdd4d8 #fff; }

.nav-tabs .nav-link:hover {
  border: 1px solid #cdd4d8; }

.nav-tabs .nav-link.disabled {
  color: #cdd4d8; }

.nav-tabs {
  border-bottom: 1px solid #cdd4d8; }

.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  color: #fff;
  cursor: default;
  background: #9c64b8;
  border-radius: 2px; }

.nav-pills .nav-link {
  text-align: center;
  border-radius: 2px;
  padding: 0.75rem 1.25rem; }

.nav-pills .nav-link.active {
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.nav-pills .nav-link.active:hover {
  background-color: #8853a1;
  border-color: #8853a1; }

.tab-w-bar {
  background: #9c64b8;
  border-radius: 50px;
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.nav-pills.tab-w-bar .nav-item.show .nav-link, .nav-pills.tab-w-bar .nav-link.active {
  color: #fff;
  cursor: default;
  background: #8853a1;
  border-radius: 50px; }

.card .tab-w-bar {
  margin-top: -50px; }

.card-w-bar {
  margin-top: 46px; }

/*--- end of navs ---*/
/*--- navbars ---*/
.navbar {
  padding: 1rem 1.25rem;
  border-radius: 0; }

.container .navbar {
  padding-left: 0;
  padding-right: 0; }

.container .navbar.navbar-content {
  padding-left: 15px;
  padding-right: 15px; }

.menu-container {
  max-width: 1280px;
  margin: 0 auto; }

.nav-link {
  font-weight: 700 !important;
  font-family: "Roboto Slab", serif !important;
  letter-spacing: 0.1rem; }

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }

.navbar-contained.fixed-top.has-top-menu-big {
  top: 88px;
  max-width: 1260px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.1); }

.navbar-contained.fixed-top.navscroll {
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.05), 0 0px 5px rgba(0, 0, 0, 0.1); }

.fixed-top.nav-scroll {
  padding: 0.75rem 1.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto !important;
  position: fixed !important;
  top: 0 !important;
  right: 0;
  left: 0;
  max-width: 100% !important; }

.sticky-top {
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }

.sticky-top.nav-scroll {
  padding: 0.75rem 1.25rem;
  border-radius: 0 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1); }

.nav-scroll {
  border-radius: 0 !important; }

.navbar-brand {
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-right: .75rem;
  margin-left: 0; }

.navbar-brand img {
  width: auto;
  max-height: 45px; }

.mobile-brand {
  display: none; }

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:focus {
  color: #000; }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .open > .nav-link {
  color: #9c64b8; }

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .active > .nav-link:hover, .navbar-light .navbar-nav .nav-link.active:hover, .navbar-light .navbar-nav .nav-link.open:hover, .navbar-light .navbar-nav .open > .nav-link:hover {
  color: #9c64b8; }

.navbar-light .navbar-nav .nav-link:hover {
  color: #9c64b8; }

.navbar-toggler {
  border: none !important;
  padding: .25rem 0; }

.navbar-toggler-right {
  right: 15px; }

.navbar-toggler-left {
  left: 15px; }

.navbar-light .navbar-toggler-icon {
  background-image: url("../images/menu.png");
  width: 32px;
  height: 32px; }

.navbar-inverse .navbar-toggler-icon {
  background-image: url("../images/menu-inverse.png");
  width: 32px;
  height: 32px; }

.navbar-light .navbar-toggler-left .navbar-toggler-icon {
  width: 32px;
  height: 32px; }

.navbar-inverse .navbar-toggler-left .navbar-toggler-icon {
  width: 32px;
  height: 32px; }

.navbar-collapse {
  text-align: center; }

nav .btn-sm {
  font-size: .6rem;
  line-height: 1.75;
  padding: 0.6rem 1rem !important; }

nav .btn:focus {
  box-shadow: none; }

nav .btn-xs {
  line-height: 2.3; }

.nav-button {
  margin-left: 15px; }

.nav-icon {
  height: auto; }

.nav-icon i {
  font-size: 18px; }

.nav-icon .md-icon {
  line-height: 40px;
  width: auto;
  height: auto;
  margin-left: 1rem; }

/* extra menu styles */
.stacked-menu {
  border-bottom: 2px solid #9c64b8; }

.stacked-menu .navbar-brand {
  float: none;
  width: 100%;
  text-align: center;
  margin-right: 0; }

.stacked-menu .navbar-toggler-right {
  padding-right: 10px;
  margin: 0 auto; }

.split-menu .navbar-brand {
  float: none;
  width: 100%;
  max-height: 50px;
  text-align: center; }

/* top menus */
.top-menu {
  background-color: #eee;
  padding: 20px 0; }

.top-menu.big {
  padding: 30px 0; }

.top-menu.big.has-contained-menu {
  padding: 15px 0; }

.top-menu.has-raised-content {
  padding-bottom: 60px;
  margin-bottom: 43px; }

.top-menu.big.has-contained-menu.has-raised-content {
  padding-bottom: 60px;
  margin-bottom: 43px; }

.top-menu-inverse {
  background-color: #333; }

.top-menu-primary {
  background-color: #9c64b8; }

.top-menu p {
  margin-bottom: 0; }

.top-menu .no-button a {
  color: #aaa !important; }

.top-menu .no-button a:hover {
  color: #9c64b8 !important; }

.top-menu-primary .no-button a {
  color: #fff !important; }

.top-menu-primary .no-button a:hover {
  color: #eee !important; }

.top-menu-inverse p {
  color: #aaa !important; }

.top-menu-primary p {
  color: #fff !important;
  text-align: left; }

.top-menu .left i {
  margin-right: 8px; }

.top-menu .right i {
  margin-left: 8px; }

.top-menu .right {
  float: right;
  text-align: right; }

.top-menu .right a {
  margin: 0 0 0 15px; }

.btn-top-menu {
  background: transparent;
  font-size: 10px !important;
  font-weight: 700;
  border: 2px solid #444;
  margin: -3px 0 0 10px;
  padding: 5px 0; }

.btn-top-menu:hover {
  border: 2px solid #9c64b8; }

.btn-top-menu a {
  font-size: 10px !important;
  margin: 0 !important;
  padding: 8px; }

.btn-top-menu-inverse {
  background: transparent;
  font-size: 10px !important;
  font-weight: 700;
  border: 2px solid #aaa;
  margin: -3px 0 0 10px;
  padding: 5px 0; }

.btn-top-menu-inverse:hover {
  border: 2px solid #9c64b8; }

.btn-top-menu-inverse a {
  font-size: 10px !important;
  margin: 0 !important;
  padding: 8px; }

.top-menu .features-small.has-contained-menu {
  margin: 0 17px; }

.top-menu .features-small i, .top-menu .features-small svg {
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  padding: 10px;
  border: 2px solid #eee;
  border-radius: 50%;
  width: 40px;
  height: 40px; }

/* end of top menus */
/* end of extra menu styles */
/* navbar-inverse */
.navbar-inverse .navbar-brand, .navbar-inverse .navbar-toggler {
  color: #fff; }

.navbar-inverse .navbar-nav .nav-link, .navbar-inverse .navbar-nav .nav-link:focus {
  color: #eee; }

.navbar-inverse .navbar-nav .active > .nav-link, .navbar-inverse .navbar-nav .nav-link.active, .navbar-inverse .navbar-nav .nav-link.open, .navbar-inverse .navbar-nav .open > .nav-link {
  color: #9c64b8; }

.navbar-inverse .navbar-nav .nav-link:hover, .navbar-inverse .navbar-nav .active > .nav-link:hover, .navbar-inverse .navbar-nav .nav-link.active:hover, .navbar-inverse .navbar-nav .nav-link.open:hover, .navbar-inverse .navbar-nav .open > .nav-link:hover {
  color: #9c64b8; }

.navbar-inverse .navbar-nav .nav-link:hover {
  color: #9c64b8; }

.navbar-inverse.bg-primary .navbar-nav .active > .nav-link, .navbar-inverse.bg-primary .navbar-nav .nav-link.active, .navbar-inverse.bg-primary .navbar-nav .nav-link.open, .navbar-inverse.bg-primary .navbar-nav .open > .nav-link {
  color: #fff; }

.navbar-inverse.bg-primary .navbar-nav .nav-link:hover, .navbar-inverse.bg-primary .navbar-nav .active > .nav-link:hover, .navbar-inverse.bg-primary .navbar-nav .nav-link.active:hover, .navbar-inverse.bg-primary .navbar-nav .nav-link.open:hover, .navbar-inverse.bg-primary .navbar-nav .open > .nav-link:hover {
  color: #fff; }

.navbar-inverse.bg-primary .navbar-nav .nav-link:focus, .navbar-inverse.bg-primary .navbar-nav .nav-link:hover {
  color: #fff; }

/* end of navbar-inverse */
/* custom menu */
.custom-menu .dropdown-toggle::after {
  top: 18px; }

.custom-menu-thin {
  padding-top: 15px;
  padding-bottom: 15px; }

.menu-pills .navbar-nav .nav-link {
  border-radius: 2px; }

.menu-pills .navbar-nav .active > .nav-link, .menu-pills .navbar-nav .nav-link.active, .menu-pills .navbar-nav .nav-link.open, .menu-pills .navbar-nav .open > .nav-link {
  color: #fff;
  background: #9c64b8;
  border-radius: 2px; }

.menu-pills .navbar-nav .nav-link:hover, .menu-pills .navbar-nav .active > .nav-link:hover, .menu-pills .navbar-nav .nav-link.active:hover, .menu-pills .navbar-nav .nav-link.open:hover, .menu-pills .navbar-nav .open > .nav-link:hover {
  color: #fff;
  background: #8853a1; }

.menu-line li a span {
  position: relative;
  padding-bottom: 0.5rem; }

.menu-line li.active a span {
  color: #8853a1; }

.menu-line.nav-scroll li a.active span:before {
  visibility: visible !important;
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.menu-line li a span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #9c64b8;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s; }

.menu-line li a:hover span:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1); }

.menu-line li a.active span:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1); }

.menu-icon i {
  margin-top: -3px; }

.transparent-menu {
  background: transparent; }

.transparent-menu.nav-scroll {
  background: #fff; }

.navbar-inverse.transparent-menu.nav-scroll {
  background: #111; }

.menu-icon.nav-scroll i {
  margin-top: -1.5px; }

/* end of custom menu */
/* animated hamburger icon */
.navbar-toggler {
  border: none;
  background: transparent !important; }

.navbar-toggler:focus {
  outline: none;
  background: transparent !important; }

.navbar-toggler .icon-bar {
  background-color: #000;
  transform: rotate(0deg) translate(0px, 0px);
  transition: ease all .2s; }

.navbar-inverse .navbar-toggler .icon-bar {
  background-color: #fff;
  transform: rotate(0deg) translate(0px, 0px);
  transition: ease all .2s; }

.navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 3px;
  border-radius: 3px; }

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 5px; }

.icon-bar:nth-child(2) {
  width: 22px;
  transition: ease all .2s; }

.navbar-toggler:hover > .icon-bar:nth-child(2) {
  width: 22px;
  transition: ease all .2s; }

.navbar-toggler:active > .icon-bar:nth-child(2) {
  width: 22px;
  transition: ease all .2s; }

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(1) {
  transform: rotate(45deg) translate(6.5px, 6.5px);
  transition: ease all .2s; }

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(2) {
  opacity: 0;
  transition: ease all .2s; }

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
  transition: ease all .2s; }

/* end of animated hamburger icon */
/*--- end of navbars ---*/
/*--- top-navs ---*/
.top-nav {
  padding: .625rem 1rem; }

.top-nav .right {
  float: right; }

.top-nav-light a {
  color: #000; }

.top-nav-light a:hover {
  color: #9c64b8; }

.top-nav-light a.btn {
  color: #fff; }

.top-nav-light a.btn:hover {
  color: #fff; }

.top-nav .btn-xs {
  line-height: .6rem;
  vertical-align: middle; }

.top-nav-inverse a {
  color: #aaa; }

.top-nav-inverse a:hover {
  color: #fff; }

/*--- end of top-navs ---*/
/*--- paginations ---*/
.page-link {
  color: #000;
  background-color: #e9f0f4;
  border: none;
  border-radius: 2px;
  padding: .625rem 1.1rem; }

.page-link:hover {
  color: #000;
  background-color: #cdd4d8;
  border: none; }

.page-link:active, .page-link:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important; }

.pagination .page-link {
  margin: 3px; }

.pagination-white .page-link {
  color: #000;
  background-color: #fff;
  border: 1px solid #cdd4d8; }

.pagination-white .page-link:hover {
  color: #000;
  background-color: #e9f0f4;
  border: 1px solid #cdd4d8; }

.pagination-primary .page-link {
  color: #fff;
  background-color: #9c64b8;
  border: 1px solid #8853a1; }

.pagination-primary .page-link:hover {
  color: #fff;
  background-color: #8853a1;
  border: 1px solid #8853a1; }

.page-link:active, .page-link:focus, .page-link:visited {
  color: #000; }

.pagination-primary .page-link:active, .pagination-primary .page-link:focus, .pagination-primary .page-link:visited {
  color: #fff; }

.page-item.disabled {
  cursor: not-allowed; }

.page-item.disabled .page-link {
  color: #444;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #e9f0f4;
  border-color: #cdd4d8;
  opacity: 0.5; }

.pagination-primary .page-item.disabled .page-link {
  color: #eee;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #9c64b8;
  border-color: #8853a1; }

.page-item.active .page-link {
  color: #fff;
  background: #9c64b8;
  border-color: #9c64b8; }

.pagination-primary .page-item.active .page-link {
  color: #fff;
  background-color: #8853a1;
  border-color: #8853a1; }

.page-item.active .page-link:hover {
  color: #fff;
  background-color: #8853a1;
  border-color: #8853a1; }

.pager .btn:hover, .pager .btn:focus {
  color: #fff; }

.pager {
  padding-left: 0; }

.pager span, .pager i {
  vertical-align: middle; }

.pager-left, .pager-right {
  display: inline-block; }

.pager-left i {
  margin-right: 1px; }

.pager-right i {
  margin-left: 1px; }

.pager-right {
  float: right; }

/*--- end of paginations ---*/
/*------- end of navigations -------*/
/*------- collapse-dropdowns -------*/
.accordion .card {
  background: #fff;
  border: none;
  margin-bottom: 0; }

.accordion .accordion-title {
  font-size: 1.075rem;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.3; }

.accordion.w-icon .card-header:first-child {
  border-radius: 0;
  border-bottom: 1px solid #cdd4d8; }

.primary-accordion.w-icon .card-header:first-child {
  border-radius: 0;
  border-bottom: 1px solid #8853a1; }

.accordion.w-icon .card-header {
  border-bottom: 0; }

.accordion.w-icon .card {
  border-radius: 0; }

.primary-accordion .card {
  border-color: #8853a1; }

.primary-accordion .card-header {
  background: #9c64b8; }

.dropdown-toggle::after {
  content: "\f078";
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  border: none;
  position: absolute;
  top: 18px;
  margin-left: 4px; }

.dropdown.show .dropdown-toggle::after {
  content: "\f077"; }

.btn.dropdown-toggle {
  padding-right: 25px; }

.btn.dropdown-toggle::after {
  top: 21px; }

.btn.dropdown-toggle.dropdown-toggle-split {
  padding-right: 20px; }

.btn.dropdown-toggle-split:after {
  margin-left: -3px; }

.dropup .dropdown-toggle::after {
  content: "\f077";
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  border: none;
  position: absolute; }

.dropdown-menu {
  min-width: 180px;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  z-index: 99;
  margin: 0;
  left: auto;
  right: 0;
  padding: .5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05), 0 2px 12px rgba(0, 0, 0, 0.1); }

.dropdown-menu.drop-to-right {
  left: 0;
  right: auto; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #cdd4d8;
  cursor: not-allowed;
  background-color: transparent; }

.dropdown-item {
  padding: 10px 16px;
  font-family: "Roboto Slab", serif !important;
  font-weight: 700; }

.dropdown-item:focus, .dropdown-item:hover {
  color: #9c64b8;
  text-decoration: none;
  background-color: transparent; }

.dropdown-item.active, .dropdown-item:active {
  color: #9c64b8;
  background-color: transparent; }

.dropdown-header {
  display: block;
  padding: 8px 16px;
  margin-bottom: 0;
  font-size: .75rem;
  color: #000;
  white-space: nowrap; }

.dropdown-divider {
  height: 1px;
  margin: .5rem 0;
  overflow: hidden;
  background-color: #e9f0f4; }

.dm-example {
  margin-bottom: 260px; }

.dm-example .dropdown-menu {
  display: block;
  position: relative;
  z-index: 99; }

.icon-dropdowns .dropdown-menu {
  min-width: 220px; }

.icon-dropdowns .close-icon {
  margin: 15px 0 15px 15px;
  right: 0; }

.icon-dropdowns .dropdown-toggle::after {
  display: none; }

.cart-small {
  display: flex; }

.cart-small img {
  max-width: 50px;
  max-height: 50px;
  margin-right: .5rem; }

.cart-small a {
  font-weight: 700; }

.cart-small a.text-black:hover {
  color: #9c64b8 !important; }

.cart-small-footer {
  background: #f7f7f7;
  padding: .75rem; }

.navbar-inverse .cart-small-footer, .dark .cart-small-footer {
  background: #222; }

.cart-small-footer a:hover {
  color: #9c64b8 !important; }

/*--- inverse dropdown ---*/
.dropdown-inverse {
  background: #333;
  border: none; }

.dropdown-inverse .dropdown-item {
  color: #eee; }

.dropdown-inverse .dropdown-item:focus, .dropdown-inverse .dropdown-item:hover {
  color: #fff;
  background-color: transparent; }

.dropdown-inverse .dropdown-header {
  color: #fff; }

.dropdown-inverse .dropdown-divider {
  background-color: #000; }

/*--- end of inverse dropdown ---*/
/*------- end of collapse-dropdowns -------*/
/*------- popovers & tooltips -------*/
.popover-example {
  position: relative; }

.popover-example .popover {
  position: relative;
  display: block;
  float: left;
  width: 23%;
  margin: 1%;
  z-index: 9; }

.popover {
  max-width: 276px;
  font-family: "Comfortaa", cursive;
  font-weight: 400;
  font-size: .875rem;
  background-color: #fff;
  border: 1px solid #e9f0f4;
  border-radius: 2px;
  padding: 0;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); }

.popover-header {
  color: #000 !important;
  font-size: 1rem;
  background-color: transparent;
  border-bottom: none;
  padding: .75rem 1rem 0; }

.popover-body {
  padding: .75rem 1rem; }

.popover-body p {
  font-size: .875rem; }

.popover-title {
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  border-bottom: 0;
  padding: .75rem 1rem; }

.popover-content {
  padding: 0 1rem .75rem 1rem; }

.popover-content p {
  font-size: .875rem; }

.popover.bs-tether-element-attached-top::after, .popover.popover-bottom::after {
  border-bottom-color: #e9f0f4; }

.popover.bs-tether-element-attached-top::before, .popover.popover-bottom::before {
  border-bottom-color: #e9f0f4; }

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #cdd4d8; }

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #cdd4d8; }

.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #cdd4d8; }

.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #cdd4d8; }

/*--- tooltips ---*/
.tooltips-example {
  position: relative; }

.tooltips-example .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1; }

.tooltip {
  font-family: "Comfortaa", cursive;
  font-weight: 400;
  font-size: .875rem; }

.tooltip-inner {
  max-width: 200px;
  padding: .5rem .75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 2px;
  opacity: 1; }

.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before, .tooltip.tooltip-top .tooltip-inner::before {
  border-top-color: #000; }

.tooltip.bs-tether-element-attached-left .tooltip-inner::before, .tooltip.tooltip-right .tooltip-inner::before {
  border-right-color: #000; }

.tooltip.bs-tether-element-attached-top .tooltip-inner::before, .tooltip.tooltip-bottom .tooltip-inner::before {
  border-bottom-color: #000; }

.tooltip.bs-tether-element-attached-right .tooltip-inner::before, .tooltip.tooltip-left .tooltip-inner::before {
  border-left-color: #000; }

/*--- end of tooltips ---*/
/*------- end of popovers & tooltips -------*/
/*------- progress -------*/
.progress {
  margin-bottom: 20px; }

.progress {
  font-size: .75rem;
  line-height: 1rem;
  background-color: #e9f0f4;
  border-radius: 2px;
  height: auto; }

.progress-bar {
  height: 1rem;
  color: #fff;
  background-color: #9c64b8; }

.progress-bar.animated {
  margin-bottom: -0.1px; }

.progress.text-xs {
  font-size: 0.5rem;
  line-height: 0.5rem; }

/*------- end of progress -------*/
/*------- call-to-action -------*/
.cta {
  padding: 30px 0; }

.cta-sm {
  padding: 25px 0; }

.cta-big {
  padding: 50px 0; }

/*------- end of call-to-action -------*/
/*------- tables -------*/
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eee; }

.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #eee; }

.table-inverse {
  color: #fff;
  background-color: #333; }

.table-inverse td, .table-inverse th, .table-inverse thead th {
  border-color: #fff; }

.thead-default th {
  color: #000;
  background-color: #eee; }

.thead-inverse th {
  color: #fff;
  background-color: #333; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eee; }

.table-striped.table-inverse tbody tr:nth-of-type(odd) {
  background-color: #000; }

.table-bordered td, .table-bordered th {
  border: 1px solid #eee; }

.table-hover tbody tr:hover {
  background-color: #eee; }

.table-active, .table-active > td, .table-active > th {
  background-color: #eee; }

.table-hover .table-active:hover {
  background-color: #eee; }

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #eee; }

.table-hover.table-inverse tbody tr:hover {
  background-color: #000; }

.table-inverse .table-active, .table-inverse .table-active > td, .table-inverse .table-active > th {
  background-color: #000; }

.table-hover.table-inverse .table-active:hover {
  background-color: #000; }

.table-hover.table-inverse .table-active:hover > td, .table-hover.table-inverse .table-active:hover > th {
  background-color: #000; }

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #9c64b8; }

.table-hover .table-primary:hover {
  background-color: #8853a1; }

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #8853a1; }

.table-success, .table-success > td, .table-success > th {
  background-color: #17d381; }

.table-hover .table-success:hover {
  background-color: #0db26a; }

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #0db26a; }

.table-info, .table-info > td, .table-info > th {
  background-color: #21b9d5; }

.table-hover .table-info:hover {
  background-color: #1397af; }

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #1397af; }

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffa03b; }

.table-hover .table-warning:hover {
  background-color: #df7f19; }

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #df7f19; }

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f03739; }

.table-hover .table-danger:hover {
  background-color: #c41a1c; }

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #c41a1c; }

/*------- end of tables -------*/
/*------- footers -------*/
footer {
  background: #cdd4d8;
  padding: 2rem 0; }

footer.light {
  background: #e9f0f4; }

footer.mid {
  background: #cdd4d8; }

footer.dark {
  background: #333; }

footer.dark p {
  color: #eee; }

footer.dark a {
  color: #fff; }

footer.dark a:hover {
  color: #aaa; }

footer p {
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 0; }

.footer-left-area {
  text-align: left; }

.footer-right-area {
  text-align: right; }

footer .social-icons i {
  margin-left: 5px; }

footer.dark a {
  color: #9c64b8; }

footer.dark a:hover {
  color: #8853a1; }

footer.dark.bg-primary a, footer.dark.bg-primary-hover a {
  color: #fff; }

footer.dark.bg-primary a:hover, footer.dark.bg-primary-hover a:hover {
  color: #eee; }

footer .social-icons .btn-social {
  padding: 0 7px; }

footer.dark .social-icons .btn-social {
  color: #fff; }

footer.dark .social-icons .btn-social:hover {
  color: #9c64b8; }

footer.dark.bg-primary .social-icons .btn-social:hover {
  color: #eee; }

/*--- widgets ---*/
.widget {
  margin: 15px 0; }

.footer-widgets {
  padding: 50px 0 30px 0; }

.footer-widgets .widget-title {
  font-size: 1.375rem;
  margin-bottom: 30px; }

.footer-logo {
  max-width: 250px;
  margin-bottom: 30px; }

.footer-logo-icon {
  max-width: 70px; }

.post-widget img {
  max-width: 120px;
  height: auto;
  float: left;
  margin: 0 10px 10px 0; }

.post-widget .post-small {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px; }

.footer-social i {
  font-size: 1.1rem; }

footer .social-icons i {
  font-size: 1.1rem; }

.footer-list, .list-widget {
  padding-left: 0; }

.footer-list li {
  padding-bottom: 8px; }

.footer-widgets .project-small img {
  max-width: 74px;
  margin: 5px;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s; }

.useful-links {
  padding-left: 0; }

.useful-links li {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee; }

.dark .useful-links li {
  border-color: #333; }

.footer-widgets .project-small img:hover {
  opacity: 0.8; }

/*--- end of widgets ---*/
/*------- end of footers -------*/
/*---------- end of components ----------*/
/*------- template styles -------*/
section {
  padding: 50px 0; }

section.has-scroll-top {
  padding: 90px 0; }

.boxed-content {
  background: #fff;
  padding: 40px 15px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); }

.content-wrapper-raised {
  margin-top: -130px;
  border-radius: 10px; }

.content-wrapper-raised.big-raise {
  margin-top: -160px; }

.content-wrapper-lowered {
  margin-bottom: -130px;
  border-radius: 10px; }

.content-wrapper-lowered.big-lower {
  margin-bottom: -150px; }

.content-first {
  z-index: 2 !important;
  position: relative; }

.content-second {
  z-index: 1 !important; }

.has-sticky-menu {
  padding-top: 87px; }

.has-raised-content {
  margin-bottom: 120px; }

.has-raised-content-big {
  margin-bottom: 150px; }

.parallax-section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #9c64b8;
  background-image: url("../images/pozadi_dark.jpg");
  padding: 150px 0; }

.parallax-section.small {
  background-image: url("../images/pozadi_small_dark.jpg");
  padding: 70px 0; }

section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.header-up-image {
  padding: 50px 0; }

header {
  padding: 50px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #eee; }

.header-content {
  padding: 185px 0; }

.header-content-left {
  text-align: left;
  margin-bottom: 30px; }

.header-text {
  margin: 30px 0; }

header.home-header {
  background-image: url("../images/pozadi_dark.jpg");
  padding: 30px 0; }

header.home-header .header-content {
  padding-top: 100px;
  padding-bottom: 80px; }

header.lp-header .header-content {
  padding-top: 160px;
  padding-bottom: 230px; }

header.app-header .header-content {
  padding-top: 25px;
  padding-bottom: 25px; }

.slide.one {
  background-image: url(../images/slide1.jpg); }

.slide.two {
  background-image: url(../images/slide2.jpg); }

.slide.three {
  background-image: url(../images/slide3.jpg); }

header.portfolio-header {
  background-image: url("../images/header-portfolio.jpg");
  padding: 30px 0; }

header.portfolio-header .header-content {
  padding-top: 150px;
  padding-bottom: 80px; }

header.agency-header {
  background-image: url("../images/header-agency.jpg");
  padding: 30px 0; }

header.agency-header .header-content {
  padding-top: 100px;
  padding-bottom: 90px; }

/*--- promo box ---*/
.promo-icon-bg {
  padding: 20px;
  max-width: 90px;
  max-height: 90px;
  border: 2px solid transparent;
  border-radius: 50%;
  margin: 0 auto 20px auto; }

.promo-icon-bg.icon-md {
  padding: 17px;
  max-width: 65px;
  max-height: 65px;
  border: 2px solid transparent;
  border-radius: 50%;
  margin: 0 auto 20px auto; }

.promo-icon-bg.icon-sm {
  padding: 13px;
  max-width: 50px;
  max-height: 50px;
  border: 2px solid transparent;
  border-radius: 50%;
  margin: 0 auto 15px auto; }

img.promo-box-icon {
  max-width: 50px;
  margin-bottom: 20px; }

.box-title {
  font-weight: 700;
  margin-bottom: 15px; }

.feature.block {
  margin: 30px 0; }

.feature i {
  font-size: 2.5rem; }

.feature-block-image {
  max-width: 50px; }

.feature-left .feature-icon {
  position: absolute;
  float: left;
  left: 15px; }

.feature-left .feature.block.wimage {
  text-align: left;
  padding-left: 65px; }

.feature-left .feature.block.wimage.has-promo-icon-bg {
  padding-left: 105px; }

.feature-left .feature {
  text-align: left;
  padding-left: 51px; }

.feature-center {
  text-align: center; }

.feature-center .feature-icon {
  margin-bottom: 10px; }

.feature-right .feature.block.wimage {
  text-align: right;
  padding-right: 65px; }

.feature-right .feature.block.wimage.has-promo-icon-bg {
  padding-right: 105px; }

.feature-right .feature-icon {
  position: absolute;
  float: right;
  right: 15px; }

.feature-right .feature {
  text-align: right;
  padding-right: 51px; }

.section-w-image {
  position: relative; }

.section-description {
  padding: 30px; }

/*--- end of promo box ---*/
/*--- portfolio ---*/
.project {
  margin: 15px 0; }

.no-gutter .project {
  margin: 0 !important;
  padding: 0 !important; }

.project-title {
  font-size: 1.5rem; }

.project-skill {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.06rem; }

/* lightbox */
.mfp-bg {
  background: #111 !important;
  opacity: 0.9 !important; }

.body.dark .mfp-figure:after {
  box-shadow: none !important;
  background: #333 !important; }

.mfp-figure:after {
  box-shadow: none !important;
  background: #e9f0f4 !important; }

.mfp-close {
  color: #fff;
  opacity: 0.7;
  font-size: 30px;
  top: -50px;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.mfp-close:hover {
  opacity: 1; }

.mfp-arrow {
  color: #fff;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
  opacity: 0.7 !important;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.mfp-arrow:hover {
  opacity: 1 !important; }

.mfp-arrow:after {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  top: 5px !important; }

.mfp-arrow-right:before {
  border-left: none !important; }

.mfp-arrow-left:before {
  border-right: none !important; }

.mfp-arrow-right:after {
  content: "\f30b" !important;
  font-size: 40px;
  border-right: none !important;
  margin-left: 25px !important; }

.mfp-arrow-left:after {
  content: "\f30a" !important;
  font-size: 40px;
  border-right: none !important;
  margin-left: 30px !important; }

/* end of lightbox */
/* effect show */
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 2; }

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(156, 100, 184, 0.9);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all .3s linear;
  transition: all .3s linear; }

.hovereffect:hover img {
  -ms-transform: scale(1.15);
  -webkit-transform: rotate(1.15);
  transform: scale(1.15); }

.hovereffect-title h5 {
  color: #fff;
  background: transparent;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -28px;
  width: 100%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px; }

.hovereffect-title p {
  color: #fff;
  background: transparent;
  text-align: center;
  position: absolute;
  top: 52%;
  width: 100%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px; }

.hovereffect-title i {
  font-size: 16px;
  color: #fff;
  background: transparent;
  position: absolute;
  top: 90%;
  left: 90%;
  margin-top: -8px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  opacity: 0.7; }

.tall-project .hovereffect-title i {
  top: 94%;
  left: 90%; }

.hovereffect-title i:hover {
  opacity: 1; }

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100); }

/* end of effect show */
/* info effect */
.info-effect .project {
  margin: 0 !important;
  padding: 0 !important; }

ul.portfolio.info-effect {
  margin-bottom: 0 !important; }

.portfolio.info-effect figure {
  margin: 15px !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

.portfolio.info-effect figure img {
  position: relative;
  display: block;
  min-height: 100%;
  opacity: 1; }

.portfolio.info-effect figure figcaption::before,
.portfolio.info-effect figure figcaption::after {
  pointer-events: none; }

.portfolio.info-effect figure figcaption,
.portfolio.info-effect figure a.project-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.portfolio.info-effect figure a.project-link {
  z-index: 0; }

.portfolio.info-effect figure h2.hover-title {
  word-spacing: -0.15rem; }

.portfolio.info-effect figure h2 span {
  font-weight: 300; }

.portfolio.info-effect figure p.project-skills {
  letter-spacing: 1px; }

.effect-info {
  background-color: #9c64b8; }

.effect-info img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

.effect-info:hover img {
  opacity: 0.15 !important; }

.effect-info .hover-content {
  padding-top: 33%; }

.tall-project .effect-info .hover-content {
  padding-top: 60%; }

.effect-info .project-icons {
  opacity: 0;
  font-size: 1.5em;
  color: #fff;
  text-align: center;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  z-index: 2;
  position: relative; }

.effect-info .project-icons a {
  color: #fff;
  margin: 5px;
  opacity: 0.7; }

.effect-info .project-icons a:hover {
  opacity: 1; }

.effect-info p.project-skill {
  padding: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

.effect-info:hover figcaption::before,
.effect-info:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.effect-info:hover .project-icons, .project-tall .effect-info:hover .project-icons, .effect-info:hover p.project-skill {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.effect-info .project-info {
  z-index: 3;
  position: relative;
  border-top: 2px solid #eee; }

.effect-info .project-info-skill {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 10px 15px;
  background: #9c64b8;
  max-width: 50%;
  margin: 0 auto;
  float: none; }

.project-box {
  padding: 15px; }

/* end of info effect */
/* portfolio-filter */
.portfolio-filter {
  margin-top: 30px;
  margin-bottom: 15px; }

.no-gutter .portfolio-filter {
  margin-bottom: 30px; }

.portfolio-filter.list-inline li {
  display: inline-block;
  padding: 0; }

.portfolio-filter a {
  color: #444;
  font-family: "Roboto Slab", serif !important;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 40px;
  border-radius: 2px;
  margin: 0 5px;
  padding: 8px 10px; }

.portfolio-filter a:hover {
  color: #9c64b8;
  background-color: transparent; }

.portfolio-filter a.active {
  color: #9c64b8;
  background-color: transparent; }

.portfolio-filter a.active:hover {
  color: #8853a1;
  background-color: transparent; }

/* end of portfolio-filter */
/* project */
.project-modal .modal-dialog {
  max-width: 1000px; }

.project-modal .modal-content {
  background-color: white;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); }

.project-modal .modal-header .close {
  color: #222;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  font-size: 2rem;
  line-height: 1rem;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.project-modal .modal-header .close:hover {
  color: #9c64b8; }

.project-modal .modal-footer {
  background: #222;
  border: none;
  padding: 15px 30px; }

.project-modal .modal-body {
  padding: 15px 15px 45px 15px; }

.project-info .info {
  margin-bottom: 15px; }

.project-info .info p, .project-info .info a {
  font-weight: 700;
  color: #aaa; }

.project-info .info a:hover {
  color: #9c64b8; }

.project-info .info-title {
  font-family: "Roboto Slab", serif;
  color: #777; }

.creator-info span {
  display: block;
  margin-left: 50px; }

.creator-image {
  max-width: 50px;
  border-radius: 50%;
  float: left;
  margin-right: 10px; }

/* end of project */
/*--- end of portfolio ---*/
/*--- clients section ---*/
#clients-carousel img {
  width: 50%;
  margin: 0 auto; }

#testimonials.has-background-image {
  background-image: url("../images/testimonials-bg.jpg"); }

.testimonial-image {
  max-width: 80px;
  margin: 0 auto 15px auto; }

/* video testimonials */
.video-testimonial {
  position: relative;
  background: #9c64b8; }

.video-testimonial-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  backface-visibility: hidden; }

.video-testimonial .middle {
  transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  margin-top: -49px;
  left: 0;
  right: 0;
  text-align: center; }

.video-testimonial .hover-icon i {
  color: #fff;
  font-size: 36px;
  line-height: 32px;
  padding: 30px;
  border: 3px solid #fff;
  border-radius: 50%;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.video-testimonial .hover-icon i:hover {
  opacity: 0.5; }

/* end of video testimonials */
/* about video*/
.video-about {
  position: relative;
  background: #9c64b8; }

.video-about-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  backface-visibility: hidden; }

.video-about .middle {
  transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  margin-top: -49px;
  left: 0;
  right: 0;
  text-align: center; }

.video-about .hover-icon i {
  color: #fff;
  font-size: 36px;
  line-height: 32px;
  padding: 30px;
  border: 3px solid #fff;
  border-radius: 50%;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; }

.video-about .hover-icon i:hover {
  opacity: 0.5; }

/* end of about video */
/*--- end of clients section ---*/
/*--- facts section ---*/
#facts.has-background {
  padding: 80px 0; }

.fact-item {
  margin: 15px 0; }

.fact-icon {
  font-size: 36px; }

#facts .timer {
  padding: 2px 0;
  font-size: 2rem; }

.fact-title {
  font-weight: 700;
  color: #777; }

/*--- end of facts section ---*/
/* pricing tables */
.pricing-table {
  background: #e9f0f4;
  text-align: center;
  margin: 15px 0; }

.pricing-table ul, .pricing-table ol {
  margin-bottom: 0;
  padding-left: 0; }

.pricing-title {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 30px;
  margin-bottom: 0; }

.pricing-table-price {
  background: #cdd4d8;
  font-weight: 700;
  padding: 30px;
  margin-bottom: 30px; }

.pricing-table-price.w-rounded-price {
  display: table;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto 30px auto;
  padding: 0; }

.rounded-price {
  display: table-cell;
  text-align: center;
  vertical-align: middle; }

.rounded-price .pricing-price {
  font-size: 1.75rem; }

.rounded-price .pricing-currency {
  vertical-align: 10px; }

.pricing-table-price p {
  color: #000;
  margin-bottom: 0; }

.pricing-currency {
  font-size: 1rem;
  font-weight: 700;
  vertical-align: 25px; }

.pricing-price {
  font-size: 3rem;
  font-weight: 700; }

.pricing-period {
  font-weight: 700; }

.pricing-table-content li {
  margin-bottom: 15px; }

.pricing-table-button {
  padding: 15px 0 35px; }

.featured.pricing-table {
  background: #9c64b8; }

.featured.pricing-table .pricing-title {
  color: #fff; }

.featured.pricing-table .pricing-table-content ul li {
  color: #eee; }

.featured.pricing-table .pricing-table-price {
  background: #8853a1; }

.featured.pricing-table .pricing-table-price p {
  color: #fff; }

/* end of pricing tables */
/*--- blog section ---*/
.blog.block {
  margin: 15px 0;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); }

.post-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 2; }

.post-image img {
  display: block;
  position: relative;
  -webkit-transition: all .3s linear;
  transition: all .3s linear; }

.post-image:hover img {
  -ms-transform: scale(1.05);
  -webkit-transform: rotate(1.05);
  transform: scale(1.05);
  opacity: 0.75; }

.post-title a {
  color: #222;
  font-family: "Roboto Slab", serif !important; }

.post-title a:hover {
  color: #9c64b8; }

.post-content {
  background: #fff;
  padding: 30px; }

.post-content .comments-info {
  font-family: "Roboto Slab", serif !important; }

.post-meta p {
  font-family: "Roboto Slab", serif !important; }

.small-slider.carousel-full-nav .owl-prev, .small-slider.carousel-full-nav .owl-next {
  font-size: 1.75rem !important;
  top: 46%; }

/*--- end of blog section ---*/
/*--- contact section ---*/
#contact .box-description {
  font-weight: 400; }

#contact .box-description a {
  color: #444; }

#contact .box-description a:hover {
  color: #9c64b8; }

.with-errors ul {
  margin-bottom: 0; }

.with-errors li {
  color: #f03739;
  margin-top: -22px;
  margin-left: 22px; }

.with-errors.text-area li {
  color: #f03739;
  margin-top: -30px;
  margin-left: 22px; }

.btn-submit {
  width: 100%;
  opacity: 1 !important; }

.btn-submit:hover {
  background: #8853a1 !important;
  border-color: #8853a1 !important; }

.btn-submit.btn-info:hover {
  background: #1397af !important;
  border-color: #1397af !important; }

.btn-submit.btn-success:hover {
  background: #0db26a !important;
  border-color: #0db26a !important; }

.btn-submit.btn-white:hover {
  background: #eee !important;
  border-color: #eee !important; }

.btn-submit.btn-black:hover {
  background: #333 !important;
  border-color: #333 !important; }

#msgSubmit {
  font-size: 1.25rem;
  margin-top: 15px; }

.has-danger .form-control {
  border-color: #f03739 !important; }

/*--- end of contact section ---*/
/*--- widgets ---*/
.recent-posts {
  display: inline-block;
  width: 100%;
  margin-top: 5px; }

.recent-posts.first {
  margin-top: 0; }

.recent-post-image img {
  max-width: 90px;
  float: left;
  margin-right: 15px; }

.recent-post-title {
  font-family: "Roboto Slab", serif !important;
  font-weight: 700 !important; }

/*--- end of widgets ---*/
/*--- instagram feed ---*/
.instagram-item {
  position: relative;
  background: #9c64b8; }

.instagram-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .2s ease-in-out;
  backface-visibility: hidden; }

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 0;
  right: 0;
  text-align: center; }

.instagram-item:hover .instagram-image {
  opacity: 0.1; }

.instagram-item:hover .middle {
  opacity: 1; }

.hover-icon {
  color: #fff;
  font-size: 24px; }

/*--- end of instagram feed ---*/
/*--- scroll to top ---*/
.scroll-to-top {
  background: #9c64b8;
  color: #fff;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  bottom: 20px;
  right: 20px;
  line-height: 38px;
  position: fixed;
  text-align: center;
  font-size: 18px;
  z-index: 99; }
  .scroll-to-top.is-hidden {
    opacity: 0;
    -webkit-transform: translate(0, -5px);
    -webkit-transition: -webkit-transform .2s,background .2s,color .2s,opacity 0 .2s; }
  .scroll-to-top.is-visible {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -webkit-transition: -webkit-transform .2s,background .2s,color .2s; }

.scroll-to-top:hover, .scroll-to-top:active, .scroll-to-top:focus {
  color: #fff; }

.scroll-to-top:hover {
  background: #8853a1; }

/*--- end of scroll to top ---*/
/*--- menu show-hide ---*/
.nav-top {
  background: #fff; }
  .nav-top.is-hidden {
    position: absolute;
    opacity: 0 !important;
    -webkit-transform: translate(0, 100px);
    -webkit-transition: -webkit-transform .2s,background .2s,color .2s,opacity 0 .2s; }
  .nav-top.is-visible {
    position: fixed !important;
    top: 0 !important;
    max-width: 100% !important;
    border-radius: 0 !important;
    -webkit-transform: translate(0, 0);
    -webkit-transition: -webkit-transform .2s,background .2s,color .2s; }

/*--- end of menu show-hide ---*/
/*------- end of template styles -------*/
/*------- media screens -------*/
/*--- max-widths ---*/
@media only screen and (max-width: 1200px) {
  .navbar-expand-lg .form-inline {
    display: block;
    margin: .5rem auto; } }

@media only screen and (max-width: 1024px) {
  .parallax {
    background-attachment: scroll !important; } }

@media only screen and (max-width: 991px) {
  .navbar-expand-lg > .container {
    padding-right: 15px;
    padding-left: 15px; }
  .navbar-expand-lg.menu-line.nav-scroll li a span {
    padding-bottom: 8px; }
  .navbar-collapse {
    margin-top: .625rem; }
  .navbar-expand-lg .navbar-brand {
    margin-left: 0; }
  .dropdown-menu {
    box-shadow: none;
    max-width: 300px;
    margin: 0 auto; }
  .dropdown-item {
    text-align: center; }
  .nav-icon .md-icon {
    margin: 5px; }
  .icon-dropdowns .form-inline {
    text-align: center; }
  .navbar-expand-lg .form-inline {
    display: block;
    margin: .5rem auto; }
  .icon-dropdowns .count {
    top: 5px;
    right: auto;
    margin-left: -8px; }
  .split-menu .navbar-brand {
    display: none; }
  .split-menu .navbar-brand.mobile-brand {
    display: contents; }
  .navbar-expand-lg .btn {
    margin: 15px 0 25px 0; }
  .agency-demo .header-title {
    margin-bottom: 20px; }
  .agency-demo .header-text {
    display: none; }
  header.agency-demo .header-inner {
    margin-top: -80px; }
  .after-dropdown {
    margin-left: 0; }
  .custom-menu .dropdown-toggle::after {
    top: 14px; }
  .dropdown-toggle::after {
    top: 14px; }
  .navbar-collapse .nav-link {
    padding: 1rem; }
  .nav-button {
    margin-left: 0;
    display: inline-block;
    padding-left: 18px !important;
    padding-right: 18px !important; }
  .popover-example .popover {
    max-width: 100%;
    width: 44%;
    margin: 3%; }
  .menu-item .menu-price {
    float: none; }
  .footer-left-area {
    text-align: center;
    margin-bottom: 15px; }
  .footer-right-area {
    text-align: center; }
  .cta .col-md-8, .cta .col-md-4 {
    text-align: center !important;
    margin: 0 !important; }
  .cta .col-md-4 .btn {
    margin: 12px 0 0 0 !important; }
  .cs-wrapper .newsletter-form {
    max-width: 100%; }
  .promo-icon-bg {
    padding: 18px;
    max-width: 70px;
    max-height: 70px;
    border: 2px solid transparent;
    border-radius: 50%;
    margin: 0 auto 10px auto; }
  .feature-left .feature.block.wimage.has-promo-icon-bg {
    padding-left: 85px; }
  .feature-right .feature.block.wimage.has-promo-icon-bg {
    padding-right: 85px; }
  .clients-carousel-text {
    text-align: center;
    margin-bottom: 30px; } }

/*------- end of 991px -------*/
/*------- 767px -------*/
@media only screen and (max-width: 767px) {
  body.components, html.components {
    background-color: #f7f7f7; }
  body.bg-inverse {
    background: #111; }
  .content {
    background: transparent;
    padding: 30px 0; }
  .w80, .w70, .w60, .w50, .w40, .w35, .w30, .w20, .w10 {
    max-width: 100% !important; }
  .hide-tablet {
    display: none !important; }
  section.has-scroll-top {
    padding: 50px 0; }
  .header-content-left, header-content-right {
    text-align: center; }
  header.home-header .header-content {
    padding-top: 50px;
    padding-bottom: 20px; }
  header.portfolio-header .header-content {
    padding-top: 70px;
    padding-bottom: 20px; }
  header.lp-header .header-content {
    padding-top: 80px;
    padding-bottom: 160px; }
  header.app-header .header-content {
    padding-top: 30px;
    padding-bottom: 0; }
  .promo-box.text-left, .promo-box.text-right {
    text-align: center !important; }
  .promo-box.text-left .promo-icon-bg.ml-0 {
    margin-left: auto !important; }
  .promo-box.text-right .promo-icon-bg.mr-0 {
    margin-right: auto !important; }
  .navbar-expand-sm .form-inline {
    display: block;
    margin: .5rem auto; }
  header .breadcrumb.text-right {
    text-align: left !important;
    margin-top: 15px; }
  .fact-title {
    margin-bottom: 30px; }
  .cta .col-sm-8, .cta .col-sm-4 {
    text-align: center !important;
    margin: 0 !important; }
  .cta .col-sm-4 .btn {
    margin: 12px 0 0 0 !important; }
  .timeline > li > .timeline-panel {
    width: 100%; }
  .timeline > li:not(.timeline-inverted) {
    padding-right: 0;
    padding-left: 0; }
  .timeline > li.timeline-inverted {
    padding-right: 0;
    padding-left: 0; }
  .timeline > li > .timeline-panel:before, .timeline > li > .timeline-panel:after {
    display: none; }
  .timeline > li > .timeline-panel {
    margin-top: 75px; }
  .collections {
    padding-left: 15px;
    padding-right: 15px; }
  .cs-wrapper {
    max-width: 100%; }
  #countdown {
    width: 400px !important; }
  footer .text-left, footer .text-right {
    display: block !important;
    text-align: center !important; } }

@media only screen and (max-width: 700px) {
  .top-menu {
    display: none; }
  .fixed-top.has-top-menu-big {
    top: 0 !important; }
  .navbar-brand img {
    max-height: 35px; }
  .has-sticky-menu-mobile {
    margin-top: 77px; } }

/*------- end of 767px -------*/
/*------- 575px -------*/
@media only screen and (max-width: 575px) {
  .container {
    width: 100%; }
  .navbar-expand-lg > .container {
    padding-right: 0;
    padding-left: 0; }
  .navbar-toggler-right {
    right: 0; }
  .navbar-toggler-left {
    left: 0; }
  .header-content {
    padding: 80px 15px; }
  .form-inline .form-control {
    display: block;
    width: 70%;
    margin: 0 auto; }
  .form-inline .btn {
    display: block;
    width: 70%;
    margin: 5px auto; }
  .popover-example .popover {
    width: 90%;
    margin: 5%; }
  .top-menu {
    text-align: center; }
  .top-menu .left-area {
    display: block;
    float: none !important;
    margin-bottom: 10px;
    padding: 0 !important; }
  .top-menu .right-area {
    display: block;
    float: none !important;
    padding: 0 !important; }
  #countdown {
    width: 300px !important; }
  .error-wrapper .search-form {
    max-width: 100%; }
  table.shopping-cart {
    max-width: 100%;
    margin-left: -15px; }
  table.shopping-cart > tbody > tr > td {
    font-size: 10px; }
  .shopping-cart .image {
    display: none; }
  .qty input[type="number"] {
    max-width: 35px;
    max-height: 35px;
    padding: 10px 4px 10px 5px; }
  .x-remove {
    display: none; }
  footer {
    text-align: center; } }

/*------- end of 575px -------*/
/*------- 480px -------*/
@media only screen and (max-width: 480px) {
  header h1 {
    font-size: 1.875rem; }
  header h2 {
    font-size: 1.75rem; }
  header h3 {
    font-size: 1.5rem; }
  .hide-mobile {
    display: none !important; }
  .custom-form .left-area {
    display: block;
    width: 100%;
    margin-bottom: 15px; }
  .custom-form .right-area {
    display: block;
    width: 100%;
    text-align: left; }
  .custom-form .left-area.long {
    width: 100%; }
  .custom-form .right-area.long {
    width: 100%; }
  .custom-form .left-area.short {
    width: 100%; }
  .custom-form .right-area.short {
    width: 100%; }
  .modal-footer.custom-form {
    display: block; }
  #countdown {
    width: 100% !important; } }

/*------- end of 480px -------*/
/*------- 400px -------*/
@media only screen and (max-width: 400px) {
  .top-nav {
    text-align: center; }
  .top-nav .left {
    display: block; }
  .top-nav .right {
    float: none;
    display: block; }
  .pager {
    text-align: center; }
  .pager-left, .pager-right {
    display: block; }
  .pager-right {
    float: none; } }

/*------- end of 400px -------*/
/*--- end of max-widths ---*/
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .custom-form .left-area {
    display: block;
    width: 100%;
    margin-bottom: 15px; }
  .custom-form .right-area {
    display: block;
    width: 100%;
    text-align: left; }
  .custom-form .left-area.long {
    width: 100%; }
  .custom-form .right-area.long {
    width: 100%; }
  .custom-form .left-area.short {
    width: 100%; }
  .custom-form .right-area.short {
    width: 100%; } }

/*--- min-widths ---*/
@media only screen and (min-width: 1281px) {
  .menu-rounded {
    border-radius: 5px; } }

@media only screen and (min-width: 1200px) {
  .blog.block .video-player {
    height: 233px; }
  .blog.block .video-player.blog-page-sidebar {
    height: 420px; } }

@media only screen and (min-width: 992px) {
  .container.container-lg {
    max-width: 990px; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 1rem 1.5rem; }
  .custom-menu.navbar-expand-lg .navbar-nav .nav-link {
    padding: .75rem 1rem; }
  .menu-pills .navbar-nav .nav-link {
    padding: .75rem 1.25rem;
    margin: 0 5px; }
  .navbar-expand-lg .before-btn {
    padding-right: 2.75rem !important; }
  .navbar-expand-lg .last-menu-item {
    margin-right: 0 !important;
    padding-right: 0 !important; }
  .dropdown-menu li:hover .sub-menu {
    visibility: visible; }
  .dropdown:hover .dropdown-menu {
    display: block; }
  .navbar-expand-lg .icon-dropdowns .nav-link {
    padding: .75rem !important; }
  .navbar-expand-lg.stacked-menu > .container {
    display: block; }
  .col-lg-6.sub-col-left {
    padding-right: 7.5px; }
  .col-lg-6.sub-col-right {
    padding-left: 7.5px; } }

@media only screen and (min-width: 768px) {
  .col-md-6.sub-col-left {
    padding-right: 7.5px; }
  .col-md-6.sub-col-right {
    padding-left: 7.5px;
    width: 100%; }
  #wrapper {
    padding-left: 300px; }
  #wrapper.toggled {
    padding-left: 0; }
  #sidebar-wrapper {
    width: 300px; }
  #wrapper.toggled #sidebar-wrapper {
    width: 0; }
  #page-content-wrapper {
    position: relative; }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0; }
  #icon-nav-wrapper {
    padding-left: 100px; }
  #icon-nav-wrapper.toggled {
    padding-left: 0; }
  #icon-nav-sidebar-wrapper {
    width: 100px; }
  #icon-nav-wrapper.toggled #icon-nav-sidebar-wrapper {
    width: 0; }
  #icon-nav-page-content-wrapper {
    position: relative; }
  #icon-nav-wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0; } }

@media only screen and (min-width: 576px) {
  .col-sm-6.sub-col-left {
    padding-right: 7.5px; }
  .col-sm-6.sub-col-right {
    padding-left: 7.5px; } }

/*--- end of min-widths ---*/
/*------- end of media screens -------*/
.input-group > div:not(.input-group-addon) {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group-addon.ig-right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0; }

.input-group-addon.ig-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0; }

.input-group > div:not(:last-child) > .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > div:not(:first-child) > .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-addon.ig-required {
  background-color: #0069d9;
  border-color: #0062cc;
  color: white; }

.input-minus30 {
  margin-top: -30px; }
